<template>
  <service-template
    :samples="samples"
    :on-run="handleResponse"
    :header="$t('snsd.header')"
    :subheader="$t('snsd.subheader')"
    :icon="require('@/assets/images/icons/services/ASA/SNSD/header.svg')"
  >
    <template v-slot:result>
      <audio-player
        :url="resultPath"
        :deletable="false"
        :segments="result"
        :placeholder="$t('services.generatedResult.placeholder')"
      />
    </template>
  </service-template>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorService from '@/service/utils/error';
import AudioPlayer from '@/components/AudioPlayer/Index.vue';
import ServiceTemplate from '../ServiceTemplate.vue';

export default {
  components: {
    ServiceTemplate,
    AudioPlayer,
  },
  data() {
    return {
      resultPath: '',
      result: [],
      samples: [],
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    allowedFormats() {
      return this.$consts.config.ASR.ALLOWED_FORMATS.join(',');
    },
  },
  async mounted() {
    const result = await this.$api.asa.snsd.getSampleAudios();

    this.samples = result.samples.map((sample) => ({ ...sample, path: `${this.$consts.config.CDN.SERVER}${sample.path}` }));
  },
  methods: {
    async handleResponse(formData, sample) {
      try {
        const { data } = await this.$api.asa.snsd.postSpeechNonSpeechDetection(formData);
        this.resultPath = sample.path;
        this.result = data.channels[0].segments.reduce((list, { end_time: end, start_time: start, is_speech: speech }) => {
          if (speech) list.push({ start, end });
          return list;
        }, []);
      } catch (ex) {
        ErrorService.displayErrorAlert('Unable to process information');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .generated-result__preview .wrapper {
    display: flex;
    flex-direction: column;

      .audio-player {
        margin-top: auto;
        margin-bottom: auto;
      }
  }
}
</style>
