<template>
  <el-upload
    v-loading="isLoading"
    :class="{ selected }"
    class="upload-audio flex flex-1 asr-input-option"
    action="#"
    drag
    :http-request="httpFunction"
    :accept="allowedFormats"
    :auto-upload="true"
    :disabled="disabled"
  >
    <img
      class="upload-audio-icon"
      :src="selected ? svg.UploadAudioOn : svg.UploadAudioOff"
    >
    <span class="asr-input-option-label">{{ $t(`asr.selectAudio`) }}</span>
  </el-upload>
</template>

<script>
import UploadAudioOn from '@/assets/images/icons/upload-audio-on.svg';
import UploadAudioOff from '@/assets/images/icons/upload-audio-off.svg';

const RENDER_LOADING_FIRST = 300;

export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowedFormats: {
      type: String,
      default: '',
    },
    dataUrl: {
      type: Boolean,
      default: false,
    },
    onUpload: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({ svg: { UploadAudioOn, UploadAudioOff }, isLoading: false }),
  methods: {
    httpFunction(request) {
      if (!this.dataUrl) return this.onUpload(request.file);

      this.isLoading = true;

      return setTimeout(() => {
        const reader = new FileReader();

        reader.onload = () => {
          this.onUpload(request.file, reader.result);
          this.isLoading = false;
        };

        reader.readAsDataURL(request.file);
      }, RENDER_LOADING_FIRST);
    },
  },
};
</script>

<style lang="scss">
.upload-audio {
  &.selected > .el-upload.el-upload--text > .el-upload-dragger .asr-input-option-label {
    color: white;
  }

  > .el-upload-list.el-upload-list--text {
    display: none;
  }

  > .el-upload.el-upload--text {
    width: 100%;

    > .el-upload-dragger {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;

      .upload-audio-icon {
        width: 0.46rem;
        height: 0.46rem;
      }

      .asr-input-option-label {
        margin-top: 0.16rem;
        font-size: 0.14rem;
        color: $text-primary-dimmed;
      }
    }
  }
}
</style>
