<template>
  <div class="example-audio">
    <div class="example-audio__list">
      <carousel
        padded
        :box="false"
        :options="carouselOptions"
        :step="step"
        :title="$t(`services.exampleAudio.title`)"
      >
        <div
          v-for="(audio, i) in samples"
          :slot="i"
          :key="i"
          :class="{ active: index === i, disabled }"
          class="example-audio__list__audio pointer"
          @click="() => handleClick(audio, i)"
        >
          <img
            class="musical-note"
            :src="getIcon(i)"
          >
          <audio
            v-if="!!!audio.duration"
            :ref="`audio-${refId}-${i}`"
            :src="audio.path"
            preload="metadata"
          />
          <div class="flex flex-col truncate">
            <span class="audio-title font-600 truncate">{{ audio.title }}</span>
            <span
              v-loading="!!!durations[i]"
              element-loading-spinner="el-icon-loading"
              class="audio-duration"
            >{{ durations[i] || '00:00' }}</span>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import { generateUUIDv4 } from '@/libs/hash';
import { floatSecondsToHHMMSS } from '@/libs/date';
import Carousel from '@/components/Carousel/Index.vue';
import MusicalNote from '@/assets/images/icons/musical-note.svg';
import MusicalNoteWhite from '@/assets/images/icons/musical-note-white.svg';
import MusicalNoteDsiabled from '@/assets/images/icons/musical-note-disabled.svg';

export default {
  components: { Carousel },
  props: {
    samples: {
      type: Array,
      default: () => [],
    },
    step: {
      type: Number,
      default: 2,
    },
    index: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ durations: {}, refId: generateUUIDv4() }),
  computed: {
    carouselOptions: () => ({ slidesPerView: 3, freeMode: true, spaceBetween: 0 }),
  },
  watch: {
    samples(value) {
      if (!value || value.length === 0) return;

      this.$nextTick(() => {
        this.updateDurations();
      });
    },
  },
  methods: {
    getIcon(i) {
      if (this.disabled) return MusicalNoteDsiabled;

      return this.index === i ? MusicalNoteWhite : MusicalNote;
    },
    async handleClick(audio, index) {
      if (this.disabled) return;

      this.$emit('click', audio, index);
    },
    setDuration(duration, index) {
      const durations = { ...this.durations };
      durations[index] = duration;
      this.durations = durations;
    },
    updateDurations() {
      this.samples.forEach((sample, index) => {
        if (sample.duration) return this.setDuration(sample.duration, index);

        const audio = this.$refs[`audio-${this.refId}-${index}`][0];
        audio.onloadedmetadata = () => this.setDuration(floatSecondsToHHMMSS(audio.duration), index);
        return null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.example-audio {
  margin-top: 0.25rem;

  &:first-of-type {
    margin-top: 0;
  }

  &__list {
    margin-left: -0.08rem;

    &:first-child {
      margin-left: 0 !important;
    }

    &__audio {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 0.46rem;
      padding: 0 0.06rem;
      border-radius: 4px;
      border: 1px solid #14D4B2;
      background: rgba(20, 212, 178, 0.05);

      .musical-note {
        $icon-size: 0.18rem;
        width: $icon-size;
        height: $icon-size;
        margin-left: 0.02rem;
      }

      &.active {
        border: 1px solid #14D4B2;
        box-sizing: border-box;
        box-shadow: 0px 6px 11px rgba(7, 105, 88, 0.21);
        background: #14D4B2;

        .audio-title, .audio-duration {
          color: white;
        }
      }

      .audio-title {
        display: block;
        font-size: 0.14rem;
        color: #14D4B2;
      }

      .audio-duration {
        width: fit-content;
        font-size: 0.10rem;
        color: #14D4B2;
      }

      &.disabled {
        border: 1px solid $text-dimmed;
        background: $background-gray-disabled;

        .audio-title, .audio-duration {
          color: $text-gray-disabled;
        }
      }
    }
  }
}
</style>
