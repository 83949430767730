<template>
  <service-template
    type="audio"
    service="asa"
    :run="handleRun"
    :loading="isLoading"
    :run-disabled="isDisabled"
    :icon="icon"
  >
    <template v-slot:header>
      {{ header }}
    </template>
    <template v-slot:left>
      <div class="flex flex-col">
        <samples-carousel
          :step="1"
          :index="index"
          :samples="samples"
          @click="handleSampleClick"
        />
        <step-heading
          v-if="isAuthenticated()"
          :step="0"
          :title="$t(`services.ownVoice`)"
        />
        <audio-upload
          v-if="isAuthenticated()"
          class="audio-upload"
          :allowed-formats="allowedFormats"
          :on-upload="handleFileChange"
          data-url
        />
        <audio-player
          :url="path"
          :placeholder="$t(`placeholder['${isAuthenticated() ? 'Audio preview' : 'Selected audio'}']`)"
          @delete="handleDelete"
        />
      </div>
    </template>
    <template v-slot:right>
      <generated-result
        :step="2"
        :loading="isLoading"
        :title="header"
        :service-name="header"
        :copyable-text="copyableText"
      >
        <slot name="result" />
      </generated-result>
    </template>
  </service-template>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorService from '@/service/utils/error';
import AudioPlayer from '@/components/AudioPlayer/Index.vue';
import SamplesCarousel from '@/views/pages/AIServices/ASA/SamplesCarousel.vue';
import StepHeading from '@/views/pages/AIServices/components/StepHeading/Index.vue';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import AudioUpload from '@/views/pages/AIServices/ASA/AutomaticSpeechRecognition/AudioUpload.vue';

export default {
  components: {
    ServiceTemplate,
    SamplesCarousel,
    GeneratedResult,
    AudioPlayer,
    StepHeading,
    AudioUpload,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    subheader: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    samples: {
      type: Array,
      required: true,
    },
    autoDisplayError: {
      type: Boolean,
      default: true,
    },
    onRun: {
      type: Function,
      required: true,
    },
    copyableText: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    index: -1,
    path: '',
    isLoading: false,
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
    allowedFormats() {
      return this.$consts.config.ASR.ALLOWED_FORMATS.join(',');
    },
    isDisabled() {
      return this.isLoading || !this.path;
    },
  },
  methods: {
    async handleRun() {
      if (!this.path) return;

      this.isLoading = true;
      const { data: blob } = await this.$api.util.getFileAsBlob(this.path);
      const formData = new FormData();
      formData.append('file', blob);

      try {
        await this.onRun(formData, this.samples[this.index]);
      } catch (ex) {
        if (this.autoDisplayError) ErrorService.displayErrorAlert(ex);
      } finally {
        this.isLoading = false;
      }
    },
    handleFileChange(file, dataUrl) {
      if (!file || !dataUrl) return;

      this.index = -1;
      this.path = dataUrl;
    },
    async handleSampleClick(audio, index) {
      this.index = index;
      this.path = audio.path;
    },
    handleDelete() {
      this.path = '';
      this.index = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-upload {
  min-height: 1.4rem;
  border-radius: 4px;
  border: 1px dashed #14D4B2;
  padding: 0.12rem;
  margin: 0.12rem 0;

  .audio-upload-label {
    margin-top: 0.16rem;
    font-size: 0.14rem;
    color: $text-primary-dimmed;
  }
}
</style>
